<template lang="pug">
  .sale-stop
    .add-sale-stop
      AppCheckbox(
        :value="setting.add_sale_stop"
        @change="handleAddSaleStop"
      )
    .sale-stop-tooltip
    .delete-sale-stop
      AppCheckbox(
        :value="setting.delete_sale_stop"
        @change="handleDeleteSaleStop"
      )
</template>

<script>
  export default {
    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    props: {
      setting: {
        type: Object,
        default: () => new Object()
      }
    },

    methods: {
      handleAddSaleStop(add_sale_stop) {
        const changes = add_sale_stop ? { add_sale_stop, delete_sale_stop: false } : { add_sale_stop }

        this.handleChangeSaleStop(changes)
      },

      handleDeleteSaleStop(delete_sale_stop) {
        const changes = delete_sale_stop ? { delete_sale_stop, add_sale_stop: false } : { delete_sale_stop }

        this.handleChangeSaleStop(changes)
      },

      handleChangeSaleStop(changes) {
        this.$emit("change", changes)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/set-inventory-for-long-period.sass"

  .sale-stop
    align-items: center
    display: flex

    .add-sale-stop,
    .delete-sale-stop
      width: $sale-stop-width

      ::v-deep
        .app-checkbox
          margin: 0 auto
          padding: 0

          &-icon
            margin: 0

    .sale-stop-tooltip
      margin: 0
      width: $sale-stop-tooltip-width

    .add-sale-stop,
    .delete-sale-stop
      align-items: center
      display: flex
      justify-content: space-between
</style>
